import React from "react";
import { t } from "ttag";
import { connect } from "react-redux";
import SVG from "react-inlinesvg";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import {
    IModularConfiguratorPromoCardInfoModal,
    IModularConfiguratorUpsellInfoModal,
} from "../models.interfaces";
import { IProductID } from "../../../models/nominals";
import { Dispatchers } from "../dispatchers";
import { getUpsellInfoModal, rootProductSelector } from "../selectors";
import { IProductFlair } from "../../../models/product-compare.interfaces";
import { BundleGroupTypes, ConfiguratorTypes } from "../../../constants";
import { IProduct } from "../../../models/catalogue.interfaces";
import { IImageURL } from "../../../models/nominals";
import Flair from "../../../common/Flair";
import { Image } from "../../../common/Image";

import styles from "./ConfiguratorUpsell.module.scss";
import classNames from "classnames";
import { defaults } from "../defaults";
import { PromoCardInfoModal } from "./ConfiguratorPromoCardInfoModal";
import iconQuestionMarkCircleFilled from "../../../../img/icons/question-mark-circle-filled.svg";

interface IOwnProps {
    bundleType: string;
    bundleID: number | null;
    sectionTitle: string | null;
    lineOne: string | null;
    lineTwo: string | null;
    lineThree: string | null;
    cardImage?: IImageURL | null;
    flair?: IProductFlair | null;
    isSelected: boolean;
    productUpgradeID?: IProductID;
    infoModal?: IModularConfiguratorPromoCardInfoModal | null;
    configuratorType?: ConfiguratorTypes;
}

interface IReduxProps {
    hasUpsellInfoModalContent: boolean;
    modalContent: IModularConfiguratorUpsellInfoModal | null;
    selectedRootProduct: IProduct | null;
}

interface IDispatchProps {
    updateSelectedAddons: (
        updates: Array<{ productID: IProductID; quantity: number }>,
    ) => void;
    setSelectedUpgrade: (upgradeID: IProductID | null) => void;
    showUpsellInfoModal: (bundleID: number) => void;
    setSelectedRootProduct: (rootProduct: IProduct) => void;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    isInfoModalOpen: boolean;
}

export class ConfiguratorUpsellComponent extends React.PureComponent<
    IProps,
    IState
> {
    public state: IState = {
        isInfoModalOpen: false,
    };

    private readonly onSelectChange = (
        e: React.FormEvent<HTMLInputElement>,
    ) => {
        if (this.props.productUpgradeID) {
            if (
                this.props.bundleType === BundleGroupTypes.UPGRADE_PRODUCT ||
                this.props.bundleType === BundleGroupTypes.UPGRADE_MODEL
            ) {
                if (this.props.isSelected) {
                    this.props.setSelectedUpgrade(null);
                } else {
                    this.props.setSelectedUpgrade(this.props.productUpgradeID);
                }
            } else {
                const updates = [
                    {
                        productID: this.props.productUpgradeID,
                        quantity: e.currentTarget.checked ? 1 : 0,
                    },
                ];
                this.props.updateSelectedAddons(updates);
            }
        }
    };

    private get analyticsClassName(): { [key: string]: string } {
        return {
            [BundleGroupTypes.PROMO_CARD]: "al-promo-GWP-card",
            [BundleGroupTypes.IN_CONFIGURATOR_ADD_ON]: "al-add-on-card",
            [BundleGroupTypes.IN_CONFIGURATOR_FREE_GIFT]: "al-free-gift",
            [BundleGroupTypes.UPGRADE_MODEL]: "al-change-model-upgrade",
            [BundleGroupTypes.UPGRADE_PRODUCT]: "al-standard-upgrade-card",
        };
    }

    render() {
        const isFreeGift =
            this.props.bundleType ===
            BundleGroupTypes.IN_CONFIGURATOR_FREE_GIFT;
        const isPromoCard =
            this.props.bundleType === BundleGroupTypes.PROMO_CARD;

        const cardAnalyticsClass =
            this.analyticsClassName[this.props.bundleType];
        const cardClasses = classNames({
            [styles.card]: true,
            [styles.selectedCard]: this.props.isSelected && !isFreeGift,
            [styles.freeGiftCard]: isFreeGift,
            [styles.promoCard]: isPromoCard,
            [cardAnalyticsClass]: true,
        });
        const tooltipAnalyticsClass = `${cardAnalyticsClass}-tool-tip`;
        return (
            <div className={styles.container}>
                <h2
                    className={`${styles.sectionTitle} configurator-upsell__section-title`}
                >
                    {this.props.sectionTitle}
                </h2>
                <label className={cardClasses}>
                    {/* Flair could be passed in as a prop */}
                    {this.props.flair && (
                        <Flair flair={this.props.flair} inline={true} />
                    )}
                    {/* Flair could come from the corresponding Upsell Info Modal */}
                    {this.props.modalContent?.card_flair &&
                        this.props.modalContent?.card_flair.length > 0 && (
                            <Flair
                                flair={
                                    this.props.modalContent.card_flair[0].value
                                }
                                inline={true}
                            />
                        )}
                    <div>
                        {this.props.productUpgradeID && !isFreeGift && (
                            <input
                                className={styles.checkbox}
                                type="checkbox"
                                checked={this.props.isSelected}
                                onChange={this.onSelectChange}
                            />
                        )}
                        <span className={styles.lineOne}>
                            {this.props.lineOne}
                        </span>
                    </div>
                    <p>
                        <span className={styles.lineTwo}>
                            {this.props.lineTwo}
                        </span>
                        <span className={styles.lineThree}>
                            {this.props.lineThree}
                        </span>
                    </p>
                    {this.props.cardImage && this.props.lineTwo && (
                        <Image
                            className={styles.cardImage}
                            src={this.props.cardImage}
                            alt={this.props.lineTwo}
                        />
                    )}
                </label>
                {this.props.hasUpsellInfoModalContent &&
                    this.props.bundleID && (
                        <button
                            className={`${styles.modalTrigger} ${tooltipAnalyticsClass}`}
                            onClick={this.props.showUpsellInfoModal.bind(
                                this,
                                this.props.bundleID,
                            )}
                            aria-label={t`More Information on the ${this.props.sectionTitle}`}
                        >
                            <SVG src={iconQuestionMarkCircleFilled} />
                        </button>
                    )}
                {this.props.infoModal && this.props.configuratorType && (
                    <>
                        <button
                            className={`${styles.modalTrigger} ${tooltipAnalyticsClass}`}
                            onClick={() => {
                                this.setState({
                                    isInfoModalOpen: true,
                                });
                            }}
                            aria-label={t`More Information on the ${this.props.sectionTitle}`}
                        >
                            <SVG src={iconQuestionMarkCircleFilled} />
                        </button>
                        <PromoCardInfoModal
                            configuratorType={this.props.configuratorType}
                            infoModal={this.props.infoModal}
                            isOpen={this.state.isInfoModalOpen}
                            onCloseModal={() => {
                                this.setState({
                                    isInfoModalOpen: false,
                                });
                            }}
                            modalTitle={this.props.sectionTitle}
                            title={this.props.lineTwo}
                            cardImage={this.props.cardImage}
                        />
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const upsellInfoModal = getUpsellInfoModal(rootState.configurator, {
        bundleID: ownProps.bundleID,
    });
    const state = rootState.configurator || defaults;
    return {
        hasUpsellInfoModalContent: upsellInfoModal !== null,
        modalContent: upsellInfoModal,
        selectedRootProduct: rootProductSelector(state),
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        updateSelectedAddons: (updates) => {
            dispatchers.updateSelectedAddons(updates);
        },
        setSelectedUpgrade: (upgradeID) => {
            dispatchers.setSelectedUpgrade(upgradeID);
        },
        showUpsellInfoModal: (bundleID) => {
            dispatchers.showUpsellInfoModal(bundleID);
        },
        setSelectedRootProduct: (rootProduct) => {
            dispatchers.setSelectedRootProduct(rootProduct);
        },
    };
};

export const ConfiguratorUpsell = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConfiguratorUpsellComponent);
