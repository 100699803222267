import React from "react";
import { t } from "ttag";
import { FinancingDocument } from "../../../models/financing";
import { FormCheckbox } from "../../../forms/FormCheckbox";
import { DocumentEmbed } from "../DocumentEmbed";
import styles from "./CardholderAgreement.module.scss";

export const CardholderAgreement = (props: {
    document: FinancingDocument;
    isChecked: boolean;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}) => (
    <div>
        <p className={styles.title}>{t`Cardholder Agreement`}</p>
        <FormCheckbox
            label={t`By checking this box, I agree that I have (i) read and understand the Cardholder Agreement and agree to be bound by its terms, and (ii) have provided the correct email address in order to retain a copy of the Cardholder Agreement for my records.`}
            id="id_cardholder_agreement_consent"
            name="cardholder_agreement_consent"
            checked={props.isChecked}
            onChange={props.onChange}
        />
        <DocumentEmbed document={props.document} />
    </div>
);
