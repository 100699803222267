import React from "react";
import { IProductFlair } from "../models/product-compare.interfaces";
import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";

interface IProps {
    flair: IProductFlair;
    inline?: boolean;
}

export const Flair = (props: IProps) => {
    if (!props.flair.content) {
        return null;
    }

    return (
        <RichTextBlock
            inline={props.inline}
            className={`flair ${props.flair.style}`}
            value={props.flair.content as string}
        ></RichTextBlock>
    );
};

export default Flair;
