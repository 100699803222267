import React, { useMemo } from "react";
import classNames from "classnames";
import { t } from "ttag";
import { FinancingModalTrigger } from "../FinancingModalTrigger";
import { getDefaultFinancingPlan } from "../../../utils/financing";
import {
    FinancingModalTriggerTheme,
    FinancingCardType,
} from "../../../constants";
import { FinancingCardImage } from "../FinancingCardImage";
import { Content } from "./Content";

interface Props {
    cardType: FinancingCardType;
    applicationSource?: string;
    theme?: FinancingModalTriggerTheme;
    aprInfoID?: string | null;
}

const prequalifyClassesMobile = classNames({
    "button": true,
    "pre-approval-ad__button": true,
    "pre-approval-ad__button--block": true,
    "pre-approval-ad__button--check": true,
    "al-homepage__pre-approval-ad--pre-qualify": true,
    "al-homepage__pre-approval-ad--pre-qualify-mobile": true,
});
const applyNowClassesMobile = classNames({
    "pre-approval-ad__apply-alternate-link": true,
    "al-homepage__pre-approval-ad--apply-now": true,
    "al-homepage__pre-approval-ad--apply-now-mobile": true,
});
const iconClasses = classNames({
    "pre-approval-ad__icon": true,
    "pre-approval-ad__icon--block": true,
    "pre-approval-ad__icon--card": true,
});

export const FinancingModalTriggerBlock = (props: Props) => {
    const defaultPlan = useMemo(getDefaultFinancingPlan, []);
    const content = defaultPlan ? (
        <Content
            plan={defaultPlan}
            theme={props.theme}
            aprInfoID={props.aprInfoID}
            applicationSource={props.applicationSource}
        />
    ) : null;
    return (
        <div className="pre-approval-ad__block-container">
            <div className="pre-approval-ad pre-approval-ad--block l-capped-width u-flex-container">
                <FinancingCardImage
                    className={iconClasses}
                    cardType={props.cardType}
                />
                {props.theme === FinancingModalTriggerTheme.CARD_OVERLAP ||
                props.theme === FinancingModalTriggerTheme.SPECIAL ? (
                    <div>{content}</div>
                ) : (
                    content
                )}
            </div>
            <div className="block__pre-approval-ad--mobile pre-approval-ad u-flex-container">
                <div className="pre-approval-ad__see pre-approval-ad__see--block-mobile">
                    <div className="pre-approval-ad__see--bold">
                        {t`Check if you pre-qualify`}
                    </div>
                    <div className="pre-approval-ad__see--small">
                        {t`without affecting your credit score.`}
                    </div>
                </div>
                <FinancingModalTrigger
                    modalType="prequal-app"
                    className={prequalifyClassesMobile}
                    applicationSource={props.applicationSource}
                >
                    {t`Check Now`}
                </FinancingModalTrigger>
                <div className="pre-approval-ad__apply-alternate">
                    {t`or`}
                    <FinancingModalTrigger
                        modalType="full-app"
                        className={applyNowClassesMobile}
                        applicationSource={props.applicationSource}
                    >
                        {t`Apply Now`}
                    </FinancingModalTrigger>
                </div>
            </div>
        </div>
    );
};
