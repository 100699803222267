import React from "react";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { t } from "ttag";
import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";
import { getPageSetting } from "../../../utils/settings";
import { IProductID, IImageURL, isoImageURL } from "../../../models/nominals";
import { IViewport, BreakPoint } from "../../../models/screen.interfaces";
import { RawHTML } from "../../../common/RawHTML";
import { Image } from "../../../common/Image";
import { IGallery, ISlide } from "../models";
import styles from "./gallery.module.scss";
import iconCarouselArrowLeft from "../../../../img/icons/carousel-arrow-left.svg";
import iconCarouselArrowRight from "../../../../img/icons/carousel-arrow-right.svg";
import iconArrowLeftBlue from "../../../../img/icons/arrow_left_blue.svg";
import iconArrowRightBlue from "../../../../img/icons/arrow_right_blue.svg";

interface IProps {
    gallery: IGallery;
    defaultThumbnail: IImageURL;
    viewport: IViewport;
}

interface IState {
    currentSlide: number;
    currentProduct: IProductID | null;
}

enum GalleryCaption {
    SEALY_BLUE = "gallery-caption--sealy-blue",
    SEALY_YELLOW = "gallery-caption--sealy-yellow",
    SEALY_LIME_YELLOW = "gallery-caption--sealy-lime-yellow",
    SEALY_LIGHT_GREEN = "gallery-caption--sealy-light-green",
    SEALY_ALIZARIN_CRIMSON = "gallery-caption--sealy-alizarin-crimson",
    ORIENT = "gallery-caption--orient",
    RAZZMATAZZ = "gallery-caption--razzmatazz",
    YELLOW = "gallery-caption--yellow",
    TEAL = "gallery-caption--teal",
}

enum GalleryTextAlignment {
    CENTER = "gallery-caption--text-center",
    RIGHT = "gallery-caption--text-right",
}

export class Gallery extends React.PureComponent<IProps, IState> {
    private readonly containerRef = React.createRef<HTMLDivElement>();
    private readonly innerRef = React.createRef<HTMLDivElement>();
    public state: IState = {
        currentSlide: 0,
        currentProduct: this.props.gallery.product,
    };

    private get isMediumScreenWidth() {
        return this.props.viewport.width <= BreakPoint.MEDIUM;
    }

    private get isSmallScreenWidth() {
        return (
            Math.min(
                this.props.viewport.width,
                document.documentElement.clientWidth,
            ) <= BreakPoint.SMALL
        );
    }

    private get currentSlide() {
        if (this.props.gallery.product !== this.state.currentProduct) {
            this.resetGallery();
            return this.props.gallery.gallery.collection[0];
        }
        return this.props.gallery.gallery.collection[this.state.currentSlide];
    }

    private resetGallery() {
        this.setState({
            currentSlide: 0,
            currentProduct: this.props.gallery.product,
        });
    }

    private updateSlide(slideIdx: number) {
        this.setState(
            {
                currentSlide: slideIdx,
            },
            () => this.handleScroll(),
        );
    }

    public handleScroll = () => {
        const container = this.containerRef.current;
        const innerDiv = this.innerRef.current;
        if (!container || !innerDiv || !innerDiv) {
            return;
        }
        const currentImage = innerDiv.children[this.state.currentSlide];

        if (currentImage) {
            const containerRect = container.getBoundingClientRect();
            const imageRect = currentImage.getBoundingClientRect();

            // Check if the image is outside the container's left or right boundaries
            if (
                imageRect.left < containerRect.left ||
                imageRect.right > containerRect.right
            ) {
                // Scroll the image into view
                currentImage.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "nearest",
                });
            }
        }
    };

    private prevSlide() {
        this.setState(
            (state) => {
                return {
                    ...state,
                    currentSlide:
                        state.currentSlide <= 0
                            ? this.props.gallery.gallery.collection.length - 1
                            : state.currentSlide - 1,
                };
            },
            () => this.handleScroll(),
        );
    }

    private nextSlide() {
        this.setState(
            (state) => {
                return {
                    ...state,
                    currentSlide:
                        state.currentSlide >=
                        this.props.gallery.gallery.collection.length - 1
                            ? 0
                            : state.currentSlide + 1,
                };
            },
            () => this.handleScroll(),
        );
    }

    private renderSlide(slide: ISlide) {
        const captionClasses = classNames({
            [styles.galleryCaption]: !slide.caption_style,
            [styles.galleryCaptionSealyBlue]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.SEALY_BLUE,
            [styles.galleryCaptionSealyYellow]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.SEALY_YELLOW,
            [styles.galleryCaptionSealyLimeYellow]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.SEALY_LIME_YELLOW,
            [styles.galleryCaptionSealyLightGreen]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.SEALY_LIGHT_GREEN,
            [styles.galleryCaptionSealyAlizarinCrimson]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.SEALY_ALIZARIN_CRIMSON,
            [styles.galleryCaptionOrient]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.ORIENT,
            [styles.galleryCaptionRazzmatazz]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.RAZZMATAZZ,
            [styles.galleryCaptionYellow]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.YELLOW,
            [styles.galleryCaptionTeal]:
                !!slide.caption_style &&
                slide.caption_style === GalleryCaption.TEAL,
            [styles.galleryCaptionTextCenter]:
                !!slide.caption_text_alignment &&
                slide.caption_text_alignment === GalleryTextAlignment.CENTER,
            [styles.galleryCaptionTextRight]:
                !!slide.caption_text_alignment &&
                slide.caption_text_alignment === GalleryTextAlignment.RIGHT,
        });
        return slide.content.map((contentBlock) => {
            if (contentBlock.type === "video_block") {
                return (
                    <RawHTML
                        key={contentBlock.id}
                        className={styles.videoContainer}
                        html={contentBlock.value}
                    />
                );
            }

            if (!contentBlock.value) {
                return null;
            }

            return (
                <figure key={contentBlock.id}>
                    <picture>
                        {contentBlock.value.renditions?.mobile_img && (
                            <source
                                media={`(max-width: ${contentBlock.value.renditions.mobile_img.width}px)`}
                                srcSet={isoImageURL.unwrap(
                                    contentBlock.value.renditions.mobile_img
                                        .url,
                                )}
                            />
                        )}
                        <Image
                            src={contentBlock.value.url}
                            alt={contentBlock.value.title}
                            lazy={getPageSetting("app-slug") !== "cocoon"}
                        />
                        <title>{contentBlock.value.title}</title>
                    </picture>
                    {slide.caption && (
                        <figcaption className={captionClasses}>
                            {slide.caption}
                        </figcaption>
                    )}
                </figure>
            );
        });
    }

    render() {
        return (
            <span className={styles.root}>
                {this.isSmallScreenWidth && (
                    <div className={styles.slideCount}>
                        {interpolate(t`%(idx)s/%(total)s`, {
                            idx: `${this.state.currentSlide + 1}`,
                            total: `${this.props.gallery.gallery.collection.length}`,
                        })}
                    </div>
                )}
                {this.isMediumScreenWidth && (
                    <div className={styles.slideButtons}>
                        <button
                            onClick={this.prevSlide.bind(this)}
                            aria-label={t`Previous`}
                        >
                            <SVG
                                aria-hidden="true"
                                src={iconCarouselArrowLeft}
                            />
                        </button>
                    </div>
                )}

                {this.renderSlide(this.currentSlide.value)}

                {this.isMediumScreenWidth && (
                    <div className={styles.slideButtons}>
                        <button
                            onClick={this.nextSlide.bind(this)}
                            aria-label={t`Next`}
                        >
                            <SVG
                                aria-hidden="true"
                                src={iconCarouselArrowRight}
                            />
                        </button>
                    </div>
                )}
                {!this.isSmallScreenWidth && (
                    <nav>
                        <button
                            className={styles.navButton}
                            onClick={this.prevSlide.bind(this)}
                            aria-label={t`Previous`}
                        >
                            <SVG aria-hidden="true" src={iconArrowLeftBlue} />
                        </button>
                        <div
                            className={styles.previews}
                            ref={this.containerRef}
                        >
                            <div className={styles.inner} ref={this.innerRef}>
                                {this.props.gallery.gallery.collection.map(
                                    (slide, i) => {
                                        const slideNum = i + 1;
                                        const label = `Slide ${slideNum}`;
                                        const firstContentBlock =
                                            slide.value.content[0];
                                        if (!firstContentBlock.value) {
                                            return null;
                                        }
                                        const thumbnailURL =
                                            firstContentBlock &&
                                            firstContentBlock.type === "image"
                                                ? firstContentBlock.value
                                                      .renditions?.thumbnail
                                                      ?.url
                                                : slide.value.video_thumbnail
                                                      ?.renditions?.thumbnail
                                                      ?.url;
                                        const isCurrentThumbnail =
                                            i === this.state.currentSlide;
                                        return (
                                            <button
                                                key={i}
                                                className={styles.previewButton}
                                                onClick={() => {
                                                    this.updateSlide(i);
                                                }}
                                                aria-current={
                                                    isCurrentThumbnail
                                                }
                                            >
                                                <img
                                                    src={isoImageURL.unwrap(
                                                        thumbnailURL ||
                                                            this.props
                                                                .defaultThumbnail,
                                                    )}
                                                    alt={label}
                                                />
                                            </button>
                                        );
                                    },
                                )}
                            </div>
                        </div>
                        <button
                            className={styles.navButton}
                            onClick={this.nextSlide.bind(this)}
                            aria-label={t`Next`}
                        >
                            <SVG aria-hidden="true" src={iconArrowRightBlue} />
                        </button>
                    </nav>
                )}
            </span>
        );
    }
}
