import React, { useRef } from "react";
import { t } from "ttag";
import SVG from "react-inlinesvg";
import {
    FinancingValueProp,
    FinancingAccountInquiry,
    FinancingDocument,
    FinancingDocumentType,
} from "../../../models/financing";
import format from "../../../utils/format";
import { strings } from "../../../localization";
import { Trans } from "../../../common/Trans";
import { openPrintWindow } from "./printing";
import { CardholderAgreement } from "./CardholderAgreement";
import { closeModal, setAgreeToCardholderAgreement } from "../reducers";
import { useAppDispatch } from "../../reducers";
import iconCheckBadgeGreenCircle from "../../../../img/icons/check-badge-green-circle.svg";

import styles from "./index.module.scss";

export const FinancingFullApproval = (props: {
    account: FinancingAccountInquiry;
    documents: FinancingDocument[];
    hasAgreedToCardholderAgreement: boolean;
    valueProps?: FinancingValueProp[];
}) => {
    const dispatch = useAppDispatch();
    const container = useRef<HTMLDivElement | null>(null);
    const onPrint = () => {
        if (container.current) {
            openPrintWindow(container.current);
        }
    };
    const cardholderAgreementDoc = props.documents.find(
        (doc) =>
            doc.document_type === FinancingDocumentType.CARDHOLDER_AGREEMENT,
    );
    return (
        <div className={styles.wrapper} ref={container}>
            <SVG className={styles.icon} src={iconCheckBadgeGreenCircle} />
            <h3 className={styles.title}>
                {t`Congratulations, you're approved.`}
            </h3>
            <p className={styles.welcome}>
                <Trans
                    fmtString={t`Please take note of or <PrintLink>print</PrintLink> your account information now. You will not have access to it again until you receive your card in the mail.`}
                    data={{
                        PrintLink: (content) => (
                            <button
                                key="1"
                                className={styles.printLink}
                                onClick={onPrint}
                            >
                                {content}
                            </button>
                        ),
                    }}
                />
            </p>
            <div className={styles.numbersWrapper}>
                <div className={styles.numbersFirstLine}>
                    <div className={styles.numbersTitle}>
                        {t`account number`}
                    </div>
                    <div className={styles.numbersData}>
                        {format.wellsFargoAccountNumber(
                            props.account.account_number,
                        )}
                    </div>
                </div>
                <div className={styles.numbersLine}>
                    <div className={styles.numbersTitle}>{t`credit limit`}</div>
                    <div className={styles.numbersData}>
                        {format.money(props.account.credit_limit)}
                    </div>
                </div>
            </div>
            <p
                dangerouslySetInnerHTML={{
                    __html: strings.get("FINANCING_WELCOME_MSG") || "",
                }}
            />
            {cardholderAgreementDoc && (
                <CardholderAgreement
                    document={cardholderAgreementDoc}
                    isChecked={props.hasAgreedToCardholderAgreement}
                    onChange={(e) => {
                        dispatch(
                            setAgreeToCardholderAgreement({
                                hasAgreed: e.currentTarget.checked,
                            }),
                        );
                    }}
                />
            )}
            <button
                className="button"
                disabled={
                    cardholderAgreementDoc &&
                    !props.hasAgreedToCardholderAgreement
                }
                onClick={(e) => {
                    e.preventDefault();
                    dispatch(closeModal());
                }}
            >
                Continue
            </button>
        </div>
    );
};
