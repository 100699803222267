import React from "react";
import { t } from "ttag";
import SVG from "react-inlinesvg";
import { IVoucherDiscount } from "../../../models/catalogue.interfaces";
import { Image } from "../../../common/Image";
import iconTrashCan from "../../../../img/icons/trash-can.svg";
import styles from "./VoucherLineMinimalEnhanced.module.scss";

interface IProps {
    voucherData: IVoucherDiscount;
    onRemoveLine: (code: string) => void;
}

export const VoucherLineMinimal = (props: IProps) => {
    return (
        <section className={styles.voucherLine}>
            {props.voucherData.desktop_image && (
                <Image
                    alt={props.voucherData.name}
                    src={props.voucherData.desktop_image}
                />
            )}
            <div className={styles.titleContainer}>
                <h2 className={styles.productTitle}>
                    {props.voucherData.name}
                </h2>
                <span>
                    {t`Qty:`} &nbsp; {props.voucherData.freq}
                </span>
            </div>
            <div className={styles.codeContainer}>
                <button
                    aria-label={t`Remove`}
                    onClick={() =>
                        props.onRemoveLine(props.voucherData.voucher.code)
                    }
                    className={`${styles.removeButton} al-voucher-line__button--remove`}
                >
                    <SVG
                        className={styles.icon}
                        src={iconTrashCan}
                        aria-hidden="true"
                    />
                </button>
            </div>
        </section>
    );
};
