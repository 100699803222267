import React from "react";
import { t } from "ttag";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import { IBasket } from "../../../models/catalogue.interfaces";
import { FinancingPlan } from "../../../models/financing";
import format from "../../../utils/format";
import { ErrorBoundary } from "../../../common/ErrorBoundary";
import { ModalStyles } from "../../../common/Modal";
import { UncontrolledPopover } from "../../../common/Popover";
import { Link } from "../../../common/Link";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { Loaders } from "../loaders";
import { Dispatchers } from "../dispatchers";
import { Actions } from "../actions";
import { defaults } from "../defaults";
import {
    BasketLineVariant,
    PredictedDeliveryDateDisplayType,
    PreferredDeliveryDateDisplayType,
} from "../constants";
import { BasketLines } from "../components/BasketLines";
import { BreakPoint } from "../../../models/screen.interfaces";
import { getViewportBreakpoint } from "../../../utils/detectMobile";
import { IWebPageURL } from "../../../models/nominals";
import iconCartOpen from "../../../../img/icons/cart-open.svg";
import styles from "./BasketMenuIcon.module.scss";

interface IOwnProps {
    basketURL: IWebPageURL;
    checkoutURL: IWebPageURL;
    basketLineVariant: BasketLineVariant;
    isPageInsideIFrame: boolean;
    className?: string;
    buildPromoComponents?: (closeModal: () => void) => JSX.Element | null;
    children?: React.ReactNode;
    addPopperArrow?: boolean;
    getPreHeaderHeight?: number | null;
    customIcon?: string;
    showLabel?: boolean;
}

interface IReduxProps {
    totalQuantity: number;
    basket: IBasket | null;
    financingPlans: FinancingPlan[];
}

interface IDispatchProps {
    loaders: Loaders;
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class BasketMenuIconComponent extends React.Component<IProps, IState> {
    private readonly popover = React.createRef<UncontrolledPopover>();

    componentDidMount() {
        this.props.loaders.loadBasketIfOutdated(this.props.basket);
    }

    private readonly getProductUpsell = () => {
        return null;
    };

    private isMobileWidth() {
        const currentBreakpoint = getViewportBreakpoint();
        return currentBreakpoint < BreakPoint.SMALL;
    }

    private readonly onAfterOpen = () => {
        if (this.isMobileWidth()) {
            document.body.classList.add("overflow--y-hidden");

            if (this.props.addPopperArrow) {
                document.documentElement.style.setProperty(
                    "--basket-popover--height-popover-with-arrow-modifier",
                    this.props.getPreHeaderHeight
                        ? `${this.props.getPreHeaderHeight}px`
                        : "0px",
                );
            }
        } else {
            document.body.classList.add("overflow--auto");
        }
    };

    private readonly onAfterClose = () => {
        document.body.classList.add("overflow--initial");
    };

    private readonly onRequestClose = () => {
        if (this.popover.current) {
            this.popover.current.requestClose();
        }
    };

    private buildBasketIcon() {
        const shouldHideLabel =
            !this.props.showLabel ||
            (this.props.showLabel && this.isMobileWidth());

        const labelClasses = classNames({
            "ada-screenreader-only": shouldHideLabel,
            [styles.label]: !shouldHideLabel,
        });
        return (
            <>
                <span className={labelClasses}>View Cart</span>
                <span
                    className={`${styles.cartNumber} basket-menu-icon__cart-number`}
                >
                    {this.props.totalQuantity}
                </span>
                <span className="ada-screenreader-only"> items in cart.</span>
                <SVG
                    aria-hidden="true"
                    className={styles.icon}
                    title={t`Cart Icon`}
                    src={
                        this.props.customIcon
                            ? this.props.customIcon
                            : iconCartOpen
                    }
                />
            </>
        );
    }

    render() {
        const qtyLabel = this.props.totalQuantity === 1 ? "Item" : "Items";
        const isBasketEmpty = this.props.totalQuantity === 0;

        const classes = classNames({
            [styles.button]: true,
            [`${this.props.className}`]: !!this.props.className,
            [styles.filled]: !isBasketEmpty,
        });

        const basketPopoverClasses = classNames({
            [styles.content]: this.props.addPopperArrow,
            [styles.popover]: !this.props.addPopperArrow,
            "basket-popover": true, // LEGACY CSS CLASS
            "basket-popover--addition": this.props.basket?.lines.length
                ? this.props.basket.lines.length > 2
                : false,
        });

        const modalStyle: ModalStyles = {
            overlay: {
                position: "absolute",
                backgroundColor: "rgba(255, 255, 255, 0)",
            },
            content: {
                border: "none",
                boxShadow: "none",
                height: this.props.isPageInsideIFrame ? "inherit" : "100%",
            },
        };

        const basketSubtotal = this.props.basket
            ? this.props.basket.total_excl_tax
            : "0.00";

        return (
            <UncontrolledPopover
                ref={this.popover}
                triggerContent={this.buildBasketIcon()}
                triggerBtnProps={{
                    className: classes,
                }}
                popperOptions={{
                    placement: "bottom",
                    modifiers: this.props.addPopperArrow
                        ? [
                              {
                                  name: "offset",
                                  options: {
                                      offset: [-100, 0],
                                  },
                              },
                          ]
                        : [],
                }}
                modalProps={{
                    contentLabel: t`Your Cart`,
                    className: this.props.addPopperArrow
                        ? styles.popoverWithArrow
                        : "",
                    style: this.props.addPopperArrow ? {} : modalStyle,
                    portalClassName: this.props.addPopperArrow
                        ? styles.modal
                        : "ReactModalPortal",
                    bodyOpenClassName: "basket-modal-portal-body-open",
                    closeBtnProps: {
                        style: {
                            top: "2rem",
                            right: "2rem",
                        },
                    },
                }}
                showArrow={this.props.addPopperArrow}
                arrowCSSClass={styles.popperArrow}
                onAfterOpen={this.onAfterOpen}
                onAfterClose={this.onAfterClose}
            >
                <div className={basketPopoverClasses}>
                    <h2 className={styles.popoverTitle}>
                        <Link href={this.props.basketURL} target="_top">
                            <strong className={styles.popoverTitleHighlight}>
                                {t`Your Cart`}
                            </strong>{" "}
                            <span>
                                ({this.props.totalQuantity} {qtyLabel})
                            </span>
                        </Link>
                    </h2>
                    <div
                        className={`${styles.popoverLines} basket-popover__lines`}
                    >
                        <BasketLines
                            financingPlans={this.props.financingPlans}
                            basket={this.props.basket}
                            getProductUpsell={this.getProductUpsell}
                            showValueProps={false}
                            predictedDeliveryDates={
                                PredictedDeliveryDateDisplayType.DISABLED
                            }
                            preferredDeliveryDates={
                                PreferredDeliveryDateDisplayType.DISABLED
                            }
                            loadConcreteBundles={
                                this.props.loaders.loadConcreteBundles
                            }
                            addBasketLine={this.props.actions.addBasketLine}
                            updateBasketLineQuantity={
                                this.props.actions.updateBasketLineQuantity
                            }
                            removeBasketLine={
                                this.props.actions.removeBasketLine
                            }
                            variant={this.props.basketLineVariant}
                            isMobile={false}
                            removeVoucherCode={
                                this.props.actions.removeVoucherCode
                            }
                        />
                    </div>
                    <div className="basket-popover__totals">
                        <div
                            className={`${styles.popoverTotal} basket-popover__total`}
                        >
                            <strong className="basket-popover__total-column basket-popover__total-column--left">
                                {t`Subtotal`}
                            </strong>
                            <div className="basket-popover__total-column basket-popover__total-column--right">
                                <strong>{format.money(basketSubtotal)}</strong>
                            </div>
                        </div>
                        {this.props.buildPromoComponents &&
                            this.props.buildPromoComponents(
                                this.onRequestClose,
                            )}
                        <Link
                            href={this.props.basketURL}
                            target="_top"
                            className={`${styles.popoverButton} button button--secondary`}
                        >
                            {t`View cart`}
                        </Link>
                        {!isBasketEmpty ? (
                            <Link
                                href={this.props.checkoutURL}
                                target="_top"
                                className={`${styles.popoverButton} button`}
                                id="basket-summary__checkout--cart-tracking"
                            >
                                {t`Checkout`}
                            </Link>
                        ) : (
                            <button
                                className={`${styles.popoverButton} button`}
                                disabled={true}
                            >
                                {t`Checkout`}
                            </button>
                        )}
                    </div>
                    {this.props.children && (
                        <ErrorBoundary>{this.props.children}</ErrorBoundary>
                    )}
                </div>
            </UncontrolledPopover>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout || defaults;
    let totalQuantity = 0;
    if (state.data.basket) {
        totalQuantity = state.data.basket.lines.reduce((memo, line) => {
            return memo + line.quantity;
        }, 0);
    }
    return {
        ...ownProps,
        totalQuantity: totalQuantity,
        basket: state.data.basket,
        financingPlans: state.data.financing_plans,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    const actions = new Actions(loaders, dispatchers);
    return {
        dispatchers: dispatchers,
        loaders: loaders,
        actions: actions,
    };
};

export const BasketMenuIcon = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BasketMenuIconComponent);
